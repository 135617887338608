import { StepperWorkflowStates } from './useCartStepper.types';

export const computeSvyStepperSteps = (
    isFoodCourt: boolean | null,
    isOrderFollowUpEnabled: boolean | undefined,
    shouldAuthenticate: boolean,
    isAuthenticated: boolean,
): StepperWorkflowStates => {
    const steps = [
        {
            stateValue: 'cart',
            translation: 'cart.stepper.cart',
        },
        {
            stateValue: 'userInformation',
            translation: 'cart.stepper.name',
        },
        {
            stateValue: 'payment',
            translation: 'cart.stepper.payment',
        },
        {
            stateValue:
                isFoodCourt || isOrderFollowUpEnabled ? 'orderFollowUp' : 'orderConfirmation',
            translation:
                isFoodCourt || isOrderFollowUpEnabled
                    ? 'cart.stepper.preparation'
                    : 'cart.stepper.summary',
        },
    ];

    if (shouldAuthenticate && isAuthenticated) {
        steps.splice(1, 1);
    }

    return steps.map((step, index) => ({
        index,
        ...step,
        translation: step.translation,
    })) as StepperWorkflowStates;
};
