import { isEmailValid } from 'utils/email';
import { ChangeEvent, useCallback, useState } from 'react';
import { useAuthenticate } from 'hooks/useAuthenticate/useAuthenticate';

export const useLoginVM = () => {
    const { login, isLoginLoading, error } = useAuthenticate();

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();

    const handleUpdateEmail = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        },
        [setEmail],
    );

    const handleUpdatePassword = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
        },
        [setPassword],
    );

    const areInputsValid = useCallback(
        () => !!password?.length && isEmailValid(email),
        [email, password],
    );

    const checkEmailFormat = useCallback(() => !email || isEmailValid(email), [email]);

    const submit = useCallback(() => {
        if (!email || !password || isLoginLoading) return;
        login({ username: email, password });
    }, [email, password, isLoginLoading, login]);

    return {
        email,
        handleUpdateEmail,
        password,
        handleUpdatePassword,
        isEmailValid: checkEmailFormat(),
        areInputsValid: areInputsValid(),
        submit,
        error,
    };
};
