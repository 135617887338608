import { WorkflowStateStep } from 'hooks/useWorkflowStateMachine/types';
import { TranslationKey } from 'locales/fr';

export enum RoutesPath {
    Home = '',
    Login = 'login',
    OldRestaurants = 'restaurants',
    OldMenuHome = 'restaurants/:restaurantId',
    Restaurants = 'mode/:mode/restaurants',
    MenuHome = 'mode/:mode/restaurants/:restaurantId',
    Product = 'mode/:mode/restaurants/:restaurantId/products/:productId',
    ChoosePayment = 'payment/choose',
    CardPayment = 'payment/card',
    GooglePay = 'payment/google-pay',
    ApplePay = 'payment/apple-pay',
    Edenred = 'payment/edenred',
    Paygreen = 'payment/paygreen',
    LuncheonVoucherChoice = 'payment/voucher',
    PaymentLaterChoice = 'payment/later',
    NotFound = 'not-found',
    CrossSelling = 'cross-selling',
    Cart = 'cart',
    CartError = 'cart-error',
    UserInformation = 'user/information',
    OrderConfirmation = 'order/:orderUuid',
    OrderFollowUp = 'order/:orderUuid/follow-up',
    OrderError = 'order-error',
    TermsAndConditions = 'terms-and-conditions',
}

export type RouteDeclaration = {
    name: TranslationKey;
    path: RoutesPath;
    element: React.ReactElement;
    layout: 'drawer' | 'mobile';
    state: WorkflowStateStep;
    titleAccessor?: 'restaurantName';
};
