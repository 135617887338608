import { SvyPassword } from '@innovorder/savory';
import { Input, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { useLoginVM } from './useLoginVM';
import {
    ButtonSubmit,
    EmailInputContainer,
    InputContainer,
    MargedText,
    MargedTitle,
} from './Login.style';

const Login: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        email,
        handleUpdateEmail,
        password,
        handleUpdatePassword,
        isEmailValid,
        areInputsValid,
        submit,
        error,
    } = useLoginVM();

    return (
        <>
            <MargedTitle text={'login_page.title'} type={4}></MargedTitle>
            <MargedText text={'login_page.content'} type={2} color="secondaryTextColor" />

            <EmailInputContainer hasError={!isEmailValid}>
                <Input
                    type="email"
                    value={email}
                    hasError={!areInputsValid}
                    onChange={handleUpdateEmail}
                    label={t('login_page.inputs.email.label')}
                    labelDetail="*"
                    placeholder="login_page.inputs.email.placeholder"
                    autoFocus
                    error={!isEmailValid ? t('login_page.inputs.email.bad_format') : undefined}
                    required
                    data-testid="email-input"
                />
            </EmailInputContainer>

            <InputContainer>
                <SvyPassword
                    value={password}
                    hasError={!areInputsValid}
                    onChange={handleUpdatePassword}
                    label={t('login_page.inputs.password.label')}
                    labelDetail="*"
                    placeholder={t('login_page.inputs.password.placeholder')}
                    autoFocus
                    required
                    data-testid="password-input"
                />
            </InputContainer>

            {error && <Text type={2} color="errorColor" text={error}></Text>}

            <ButtonSubmit
                onClick={submit}
                isLoading={false}
                disabled={!areInputsValid}
                data-testid="login-btn"
                color="destructive"
                size="m"
            >
                {t('login_page.inputs.submit')}
            </ButtonSubmit>
        </>
    );
};

export default Login;
